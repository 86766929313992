import { Stack, Text } from '@mantine/core';
import { IPrescription } from 'services/types/prescription';

//  todo - prescription to return professional details
export const ProfessionalDataSignature = ({
  prescription,
}: {
  prescription: IPrescription;
}) => {
  const {
    professionalName,
    // professionalEmail,
    // professionalYearsOfExperience,
    // professionalTitle,
    // professionalSpecialties,
  } = prescription;
  return (
    <Stack spacing={0}>
      <Text weight={600}>{professionalName ? professionalName : ''}</Text>
      {/* <Group>
        <Text>
          {handleCleanAndFormatArrayProperty(professionalSpecialties)}
        </Text>
        |<Text>{professionalTitle}</Text> *
      </Group>
      <Group>
        <Text>
          {professionalEmail} | {professionalYearsOfExperience}
        </Text>
      </Group> */}
    </Stack>
  );
};

export default ProfessionalDataSignature;
