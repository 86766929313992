import { ENDPOINTS } from 'services/api/endpoints';
import { baseApi } from 'redux/baseApiSlice';
import {
  IMedicationsResponse,
  IPrescription,
} from 'services/types/prescription';

const prescriptionApiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [
    'Medications',
    'Prescriptions_by_BeneficiaryId',
    'Prescriptions_by_PatientId',
  ],
});

export const prescriptionApi = prescriptionApiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getAllMedications: builder.query<IMedicationsResponse, void>({
      query: () => ({
        url: `/${ENDPOINTS.PRESCRIPTION}/all?pageSize=1000`,
        method: 'GET',
      }),
      providesTags: ['Medications'],
    }),
    getPrescriptionsByBeneficiaryId: builder.query<
      IPrescription[],
      { beneficiaryId: string }
    >({
      query: ({ beneficiaryId }) => ({
        url: `/${ENDPOINTS.PRESCRIPTION}/by-beneficiaryId/${beneficiaryId}`,
        method: 'GET',
      }),
      providesTags: ['Prescriptions_by_BeneficiaryId'],
    }),
    getPrescriptionsByPatientId: builder.query<
      IPrescription[],
      { patientId: string }
    >({
      query: ({ patientId }) => ({
        url: `/${ENDPOINTS.PRESCRIPTION}/by-patientId/${patientId}`,
        method: 'GET',
      }),
      providesTags: ['Prescriptions_by_PatientId'],
    }),
  }),
});

export const {
  useGetAllMedicationsQuery,
  useGetPrescriptionsByPatientIdQuery,
  useGetPrescriptionsByBeneficiaryIdQuery,
} = prescriptionApi;
