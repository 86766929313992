import { createSlice } from '@reduxjs/toolkit';
import { IMedicationPrescription } from 'services/types/prescription';

interface PrescriptionState {
  SelectedPrescription: IMedicationPrescription[];
}

const initialState: PrescriptionState = {
  SelectedPrescription: [],
};

const prescriptionSlice = createSlice({
  name: 'prescription',
  initialState,
  reducers: {
    setSelectedPrescription: (state, action) => {
      state.SelectedPrescription = action.payload;
    },
    resetPrescriptionState: () => initialState,
  },
});

export const { setSelectedPrescription, resetPrescriptionState } =
  prescriptionSlice.actions;

export default prescriptionSlice.reducer;
