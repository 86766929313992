import { Stack, Table, ScrollArea, createStyles } from '@mantine/core';
import { usePrescriptionHook } from 'features/Prescriptions/hooks/usePrescriptionHook';
import {
  IMedicationPrescription,
  IPrescription,
} from 'services/types/prescription';
import { useGetPrescriptionsByBeneficiaryIdQuery } from 'services/api/prescription';
import { useUserPlansData } from 'features/Subscription/hooks/useUserPlansData';
import { SkeletonComponent } from 'components/SkeletonComponent';

export const usePatientPrescriptionTableStyles = createStyles((theme) => ({
  root: {
    overflowX: 'auto',
  },
  tableHeader: {
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[6]
        : theme.colors.gray[4],
    color: theme.black,
    fontSize: 40,
  },
  tableBody: {
    fontWeight: 500,
  },
  action: {
    cursor: 'pointer',
  },
}));

interface PatientPrescriptionTableProps {
  prescription: IPrescription;
}

export const PatientPrescriptionTable = ({
  prescription,
}: PatientPrescriptionTableProps) => {
  const { classes } = usePatientPrescriptionTableStyles();
  const prescriptionId = prescription.id;
  const { handleGetMedicationNameWithMedicationId } = usePrescriptionHook();
  const { currentUserPlanBeneficiaryId } = useUserPlansData();

  const {
    data: patientPrescriptions = [],
    isLoading: isPatientPrescriptionLoading,
  } = useGetPrescriptionsByBeneficiaryIdQuery(
    { beneficiaryId: currentUserPlanBeneficiaryId ?? '' },
    { skip: !currentUserPlanBeneficiaryId },
  );

  // Function to create a single row for a medication
  const createMedicationRow = (
    medication: IMedicationPrescription,
    medicationName: string,
    index: number,
  ) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{medicationName}</td>
        <td>{medication.dosage}</td>
        <td>{medication.format}</td>
        <td>{medication.instruction}</td>
      </tr>
    );
  };

  const createTableRowsWithBackendData = () => {
    let rowIndex = 0; // keep count of the index for its order on the table
    return patientPrescriptions
      ?.filter(
        (prescription: IPrescription) => prescription.id === prescriptionId,
      )
      .map((prescription: IPrescription) => {
        return prescription.medications.map(
          (medication: IMedicationPrescription) => {
            const medicationId = medication.medicationId;
            const medicationName = handleGetMedicationNameWithMedicationId(
              medicationId as string,
            );
            const rowKey = rowIndex++; // Use the rowIndex as the key for each row
            return createMedicationRow(medication, medicationName, rowKey);
          },
        );
      })
      .flat();
  };

  if (isPatientPrescriptionLoading) {
    return (
      <Stack w="100%" p={30}>
        <SkeletonComponent height={8} radius={'xl'} />
        <SkeletonComponent height={8} radius={'xl'} />
        <SkeletonComponent height={8} radius={'xl'} />
      </Stack>
    );
  }

  return (
    <ScrollArea>
      <Stack className={classes.root}>
        <Table withBorder sx={{ minWidth: 800 }} verticalSpacing="md">
          <thead className={classes.tableHeader}>
            <tr>
              <th>s/n</th>
              <th>Medicine Name</th>
              <th>Dosage</th>
              <th>Format</th>
              <th>Instructions</th>
            </tr>
          </thead>
          <tbody className={classes.tableBody}>
            {createTableRowsWithBackendData()}
          </tbody>
        </Table>
      </Stack>
    </ScrollArea>
  );
};
