import { useOidc } from '@axa-fr/react-oidc';
import { setAuthToken } from 'redux/accountSlice';
import { baseApi } from 'redux/baseApiSlice';
import { RESET_STORE } from 'types';
import { useAppDispatch } from 'redux/hooks';

const useLogout = () => {
  const { logout } = useOidc();
  const dispatch = useAppDispatch();

  const handleLogout = async () => {
    dispatch({ type: RESET_STORE });
    dispatch(setAuthToken(''));
    await logout('/');
    localStorage.clear();
    dispatch(baseApi.util.resetApiState());
  };

  return {
    handleLogout,
  };
};

export default useLogout;
