import { createSlice } from '@reduxjs/toolkit';
import {
  EBookAppointmentSource,
  IAppointment,
  IBookAppointmentFormValues,
} from 'services/types/appointment';
import { IProfessional } from 'services/types/professional';

interface AppointmentState {
  meetingDetails: any;
  patientAppointmentDetails: IAppointment;
  bookAppointmentStepOneForm: IBookAppointmentFormValues;
  bookingSelectedProfessional: IProfessional;
  bookingSelectedSymptons: string[];
  bookAppointmentStepThreeForm: any;
  bookAppointmentSource: EBookAppointmentSource;
}

const initialState: AppointmentState = {
  meetingDetails: null,
  patientAppointmentDetails: {} as IAppointment,
  bookAppointmentStepOneForm: {} as IBookAppointmentFormValues,
  bookingSelectedProfessional: {} as IProfessional,
  bookingSelectedSymptons: [],
  bookAppointmentStepThreeForm: {} as any,
  bookAppointmentSource: EBookAppointmentSource.BookAppointment,
};

const appointmentSlice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    setJwtMeetingDetails: (state, action) => {
      state.meetingDetails = action.payload;
    },
    setPatientAppointmentDetails: (state, action) => {
      state.patientAppointmentDetails = action.payload;
    },
    setBookAppointmentStepOneForm: (state, action) => {
      state.bookAppointmentStepOneForm = action.payload;
    },
    setBookingSelectedSymptoms: (state, action) => {
      state.bookingSelectedSymptons = action.payload;
    },
    setBookingSelectedProfessional: (state, action) => {
      state.bookingSelectedProfessional = action.payload;
    },
    setBookAppointmentStepThreeForm: (state, action) => {
      state.bookAppointmentStepThreeForm = action.payload;
    },
    setBookAppointmentSouce: (state, action) => {
      state.bookAppointmentSource = action.payload;
    },
    resetBookAppointmentForm: (state) => {
      state.bookAppointmentStepThreeForm = {};
      state.bookAppointmentStepOneForm = {} as IBookAppointmentFormValues;
      state.bookingSelectedProfessional = {} as IProfessional;
      state.bookingSelectedSymptons = [];
      state.bookAppointmentSource = EBookAppointmentSource.BookAppointment;
    },
    resetAppointmentState: () => initialState,
  },
});

export const {
  setJwtMeetingDetails,
  setPatientAppointmentDetails,
  setBookAppointmentStepOneForm,
  setBookAppointmentStepThreeForm,
  setBookingSelectedProfessional,
  setBookingSelectedSymptoms,
  setBookAppointmentSouce,
  resetBookAppointmentForm,
} = appointmentSlice.actions;
export default appointmentSlice.reducer;
