import { Stack, StackProps } from '@mantine/core';
import { PaginationComponent, usePaginationHook } from 'components/Pagination';
import { AppointmentItem } from 'features/Appointments/components/AppointmentItem';
import { IAppointment } from 'services/types/appointment';

interface AppointmentProps extends StackProps {
  appointments: IAppointment[];
}

export const Appointments = ({
  appointments,
  ...otherProps
}: AppointmentProps) => {
  const pageSize = 5;
  const { currentPage, paginatedData, handlePageChange } = usePaginationHook(
    appointments,
    pageSize,
  );

  return (
    <Stack spacing="xs" {...otherProps}>
      {paginatedData.map((appointments) => (
        <AppointmentItem
          key={appointments.id}
          symptoms={appointments?.symptoms}
          {...appointments}
        />
      ))}
      <PaginationComponent
        total={Math.ceil(appointments?.length / pageSize)}
        page={currentPage}
        onChange={handlePageChange}
      />
    </Stack>
  );
};
