import {
  Group,
  Button,
  Text,
  Input,
  createStyles,
  Textarea,
  Rating,
  Stack,
} from '@mantine/core';
import { handleFormatDateForBackend, handleGetCurrentDate } from 'utils';
import { FormEvent, useCallback, useState } from 'react';
import {
  IAppointment,
  IAppointmentReviewRequest,
} from 'services/types/appointment';
import { useAddAppointmentReviewMutation } from 'services/api/appointment';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'utils/notificationUtils';
import { logErrorMessage } from 'hooks/useLogger';
import { useAddProfessionalReviewMutation } from 'services/api/professional';
import { useUserPlansData } from 'features/Subscription/hooks/useUserPlansData';
import { IProfessionalReviewRequest } from 'services/types/professional';

interface AppointmentReviewsModalProps {
  closeModal: () => void;
  appointment: IAppointment;
}

const useStyles = createStyles((theme) => ({
  body: {
    [theme.fn.smallerThan('md')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
    [theme.fn.largerThan('md')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
    border: '1px solid',
    borderColor: theme.colors.cyan[1],
    backgroundColor: theme.colorScheme === 'dark' ? '' : theme.colors.cyan[0],
    borderRadius: theme.fontSizes.md,
    padding: theme.spacing.md * 0.6,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

export const AppointmentReviewsModal = ({
  closeModal,
  appointment,
}: AppointmentReviewsModalProps) => {
  const { classes } = useStyles();
  const [ratingValue, setRatingValue] = useState(0);
  const [reviewValue, setReviewValue] = useState('');
  const [professionalRatingValue, setProfessionalRatingValue] = useState(0);
  const [professionalReviewValue, setProfessionalReviewValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const date = new Date(handleGetCurrentDate());

  const { currentUserPlanBeneficiaryId } = useUserPlansData();

  const [addAppointmentReviewFn, { isLoading: isAddAppointmentReviewLoading }] =
    useAddAppointmentReviewMutation();
  const [
    addProfessionalReviewFn,
    { isLoading: isAddProfessionalReviewLoading },
  ] = useAddProfessionalReviewMutation();

  const handleSubmitReview = async (e: FormEvent) => {
    e.preventDefault();
    if (!ratingValue || !reviewValue) {
      setErrorMessage('Choose a rating and write a review to continue ');
      return;
    }
    const data: IAppointmentReviewRequest = {
      appointmentId: appointment.id,
      rating: ratingValue,
      review: reviewValue,
      dateAdded: `${handleFormatDateForBackend(date)}`,
      planBeneficiaryId: currentUserPlanBeneficiaryId ?? '',
    };

    const professionalData: IProfessionalReviewRequest = {
      appointmentId: appointment.id,
      professionalId: appointment.professionalId,
      planBeneficiaryId: currentUserPlanBeneficiaryId ?? '',
      rating: professionalRatingValue,
      review: professionalReviewValue,
    };

    await Promise.all([
      addAppointmentReviewFn({
        data,
      })
        .unwrap()
        .then(() =>
          showSuccessNotification(
            'appointment-success',
            'Appointment review added successfully',
          ),
        )
        .catch((error) => {
          logErrorMessage(error);
          const errorMessage = error.data?.errors?.[0]?.message || error.data;
          const displayMessage = getReviewsErrorMessage(errorMessage);
          showErrorNotification(
            'appointment-error',
            displayMessage,
            'Add appointment review error',
          );
        }),
      addProfessionalReviewFn({
        data: professionalData,
      })
        .unwrap()
        .then(() =>
          showSuccessNotification(
            'appointment-success',
            'Medical professional review added successfully',
          ),
        )
        .catch((error) => {
          logErrorMessage(error);
          const errorMessage = error.data?.errors?.[0]?.message || error.data;
          const displayMessage = getReviewsErrorMessage(errorMessage);
          showErrorNotification(
            'professional-review-error',
            displayMessage,
            'Medical professional review error',
          );
        }),
    ]);
    await closeModal();
  };

  const getReviewsErrorMessage = useCallback((errorMessage: string) => {
    if (errorMessage.includes('Invalid token. Please request a new one')) {
      return 'Your token is either expired or malfunctioned. Please request for a new token';
    }
    if (
      errorMessage === 'Token is required' ||
      errorMessage === 'Invalid parameters'
    ) {
      return 'Cannot reset password. Please request for a new token';
    }

    return 'Something went wrong while updating your appointment. Please try again later...';
  }, []);
  return (
    <form onSubmit={handleSubmitReview}>
      <Input.Wrapper className={classes.body}>
        <Stack>
          <Text size={14} weight={600}>
            How was the session?
          </Text>
          <Rating value={ratingValue} onChange={setRatingValue} />
          <Textarea
            placeholder="Write your review here"
            variant="unstyled"
            autosize
            minRows={3}
            onChange={(e) => {
              setErrorMessage('');
              setReviewValue(e.target.value);
            }}
          />
        </Stack>
        <Stack>
          <Text size={14} weight={600}>
            How was the experience with the professional?
          </Text>
          <Rating
            value={professionalRatingValue}
            onChange={setProfessionalRatingValue}
          />
          <Textarea
            placeholder="Write your review here"
            variant="unstyled"
            autosize
            minRows={3}
            onChange={(e) => {
              setErrorMessage('');
              setProfessionalReviewValue(e.target.value);
            }}
          />
        </Stack>

        <Group position="right">
          <Button
            color="red"
            variant="light"
            radius="md"
            size="sm"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            color="cyan"
            type="submit"
            radius="md"
            size="sm"
            loading={
              isAddAppointmentReviewLoading || isAddProfessionalReviewLoading
            }
          >
            Send
          </Button>
        </Group>
      </Input.Wrapper>
      <Text size="sm" align="center" color="red" py={2}>
        {errorMessage}
      </Text>
    </form>
  );
};
