import { ENDPOINTS } from 'services/api/endpoints';
import { baseApi } from 'redux/baseApiSlice';
import {
  IBeneficariesUserPlanResponse,
  ICombinedHealthAndPlanDurations,
  IUserPlanResponse,
} from 'services/types/plan';

const planApiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [
    'Plan',
    'Active_User_Plan',
    'User_Plan_History',
    'Health_and_Plan_Durations',
    'Plan_Beneficiaries',
  ],
});

export const planApi = planApiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    plans: builder.mutation<void, { data: any }>({
      query: ({ data }) => ({
        url: `/${ENDPOINTS.PAYMENT}/allocate`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Plan'],
    }),
    createNewUserPlanSubscription: builder.mutation<void, { data: any }>({
      query: ({ data }) => ({
        url: `/${ENDPOINTS.PLAN}/user-plan`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [
        'Plan',
        'Active_User_Plan',
        'User_Plan_History',
        'Health_and_Plan_Durations',
        'Plan_Beneficiaries',
      ],
    }),
    getHealthAndPlanDurations: builder.query<
      ICombinedHealthAndPlanDurations,
      void
    >({
      query: () => ({
        url: `/${ENDPOINTS.PLAN}/plans-durations`,
        method: 'GET',
      }),
      providesTags: ['Health_and_Plan_Durations'],
    }),
    getActivePlansByUserId: builder.query<
      IUserPlanResponse,
      { userId: string }
    >({
      query: ({ userId }) => ({
        url: `/${ENDPOINTS.PLAN}/user-plan/active/${userId}`,
        method: 'GET',
      }),
      providesTags: ['Active_User_Plan'],
    }),
    getUserPlanHistoryByUserId: builder.query<
      IUserPlanResponse,
      { userId: string }
    >({
      query: ({ userId }) => ({
        url: `/${ENDPOINTS.PLAN}/user-plan/user/${userId}`,
        method: 'GET',
      }),
      providesTags: ['User_Plan_History'],
    }),
    getPlanBeneficiariesByUserPlanId: builder.query<
      IBeneficariesUserPlanResponse,
      { userPlanId: string }
    >({
      query: ({ userPlanId }) => ({
        url: `/${ENDPOINTS.PLAN}/user-plan/beneficiaries/${userPlanId}`,
        method: 'GET',
      }),
      providesTags: ['Plan_Beneficiaries'],
    }),
    addBeneficiary: builder.mutation<void, { data: any }>({
      query: ({ data }) => ({
        url: `/${ENDPOINTS.PLAN}/beneficiary`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Plan_Beneficiaries'],
    }),
    updateBeneficiary: builder.mutation<void, { data: any }>({
      query: ({ data }) => ({
        url: `/${ENDPOINTS.PLAN}/beneficiary`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Plan_Beneficiaries'],
    }),
    deleteBeneficiary: builder.mutation<void, { planBeneficiaryId: any }>({
      query: ({ planBeneficiaryId }) => ({
        url: `/${ENDPOINTS.PLAN}/beneficiary/${planBeneficiaryId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Plan_Beneficiaries'],
    }),
  }),
});

export const {
  usePlansMutation,
  useGetActivePlansByUserIdQuery,
  useGetUserPlanHistoryByUserIdQuery,
  useGetHealthAndPlanDurationsQuery,
  useGetPlanBeneficiariesByUserPlanIdQuery,
  useCreateNewUserPlanSubscriptionMutation,
  useAddBeneficiaryMutation,
  useUpdateBeneficiaryMutation,
  useDeleteBeneficiaryMutation,
} = planApi;
