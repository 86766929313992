import { Button, Loader, Stack } from '@mantine/core';
import { useLoaderStyles } from 'components/Loading';

interface OidcAuthErrorProps {
  message?: string;
  buttonText?: string;
  onRetry?: () => void;
}

const OidcAuthError = ({
  message = 'Authentication went wrong',
  buttonText = 'Try again',
  onRetry = () => window.location.reload(),
}: OidcAuthErrorProps) => {
  const { classes } = useLoaderStyles();
  return (
    <Stack spacing="md" className={classes.loader} style={{ height: '100vh' }}>
      <Loader size="xl" variant="dots" />
      <h4>{message}</h4>
      <Button color="#008CB3" radius="md" size="md" onClick={onRetry}>
        {buttonText}
      </Button>
    </Stack>
  );
};

export default OidcAuthError;
