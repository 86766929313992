import { ComponentType, useState } from 'react';
import { ScheduleModes, UserGlobalInterface } from 'types';
import { IconBaseProps } from 'react-icons';
import { BsCameraVideo, BsPerson, BsTelephone } from 'react-icons/bs';

export const useAppointmentItem = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isWithin30Minutes = (timeStr: string): boolean => {
    if (!timeStr) {
      return false;
    }

    const targetTime = new Date(timeStr);
    const currentTime = new Date();
    const differenceInMilliseconds =
      targetTime.getTime() - currentTime.getTime();
    const differenceInMinutes = differenceInMilliseconds / (1000 * 60);

    return (
      (differenceInMinutes >= -30 && differenceInMinutes <= 30) ||
      differenceInMinutes === 0
    );
  };

  const ModeIconsMap: Record<
    UserGlobalInterface['mode'],
    ComponentType<IconBaseProps>
  > = {
    [ScheduleModes.Video]: BsCameraVideo,
    [ScheduleModes.Voice]: BsTelephone,
    [ScheduleModes.InPerson]: BsPerson,
    [ScheduleModes.None]: BsTelephone,
  };
  return {
    isModalOpen,
    setIsModalOpen,
    isWithin30Minutes,
    ModeIconsMap,
  };
};
