import { useGetAllMedicationsQuery } from 'services/api/prescription';
import { IMedicationPrescription } from 'services/types/prescription';

export const usePrescriptionHook = () => {
  const { data: { medications = [] } = {} } = useGetAllMedicationsQuery();
  // Function to get the medication name from the given medicationId
  const handleGetMedicationNameWithMedicationId = (
    medicationId: string,
  ): string => {
    const matchedMedication = medications?.find(
      (medicationItem: { id: string }) => medicationItem?.id === medicationId,
    );
    return matchedMedication?.name || 'Unknown Medication';
  };

  const handleGetPrescribedMedicationArray = (
    medications: IMedicationPrescription[],
  ) =>
    medications?.map((medication: IMedicationPrescription) => {
      const { medicationId } = medication;
      const medicationName = handleGetMedicationNameWithMedicationId(
        medicationId as string,
      );
      return medicationName;
    });

  return {
    handleGetMedicationNameWithMedicationId,
    handleGetPrescribedMedicationArray,
  };
};
