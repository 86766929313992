import { createSlice } from '@reduxjs/toolkit';

interface AppState {
  showSidebar: boolean;
  searchValue: string;
  searchTerm: string;
  selectedTabValue: string;
  selectedStatusTabValue: string;
  calendar: any[];
  sortedCalendar: any[];
}

const initialState: AppState = {
  showSidebar: false,
  searchValue: '',
  searchTerm: '',
  selectedTabValue: 'All',
  selectedStatusTabValue: 'Pending',
  calendar: [],
  sortedCalendar: [],
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setShowSidebar: (state, action) => {
      state.showSidebar = action.payload;
    },
    setAppSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setSelectedTabValue: (state, action) => {
      state.selectedTabValue = action.payload;
    },
    setSelectedStatusTabValue: (state, action) => {
      state.selectedStatusTabValue = action.payload;
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    setCalendar: (state, action) => {
      state.calendar = action.payload;
    },
    setSortedCalendar: (state, action) => {
      state.sortedCalendar = action.payload;
    },
  },
});

export const {
  setShowSidebar,
  setAppSearchValue,
  setSelectedTabValue,
  setSelectedStatusTabValue,
  setSearchTerm,
  setCalendar,
  setSortedCalendar,
} = appSlice.actions;

export default appSlice.reducer;
