export const BASE_URL = process.env.REACT_APP_HOMNICS_ENDPOINT;
export const API_PREFIX = 'user-api';

export const ENDPOINTS = {
  ACCOUNT: `${API_PREFIX}/account`,
  APPOINTMENT: `${API_PREFIX}/appointment`,
  BENEFIT: `${API_PREFIX}/benefit`,
  MEETING: `${API_PREFIX}/meeting`,
  NOTIFICATION: `${API_PREFIX}/notification`,
  PATIENT: `${API_PREFIX}/patient`,
  PAYMENT: `${API_PREFIX}/payment`,
  PLAN: `${API_PREFIX}/plan`,
  PRESCRIPTION: `${API_PREFIX}/prescription`,
  PROFESSIONAL: `${API_PREFIX}/professional`,
  RECORD: `${API_PREFIX}/record`,
  SUBSCRIBE: `${API_PREFIX}/subscriber`,
  COUNTRIES_STATES: 'https://countriesnow.space/api/v0.1/countries/states',
  CITIES: 'https://countriesnow.space/api/v0.1/countries/cities',
};
