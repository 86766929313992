import { useMantineTheme, Stack } from '@mantine/core';
import { AppHeader } from 'components/AppHeader';
import { UserToUpgradeSubscription } from 'features/Appointments/components/AppointmentsTabs';
import { TGroupedPlans } from 'services/types/plan';
import { handleGroupHealthPlansByCategory } from 'features/Subscription/helpers';
import { Outlet } from 'react-router-dom';
import { getPageDefaultStyles } from 'themes';
import { useTranslation } from 'react-i18next';
import { useUserPlansData } from 'features/Subscription/hooks/useUserPlansData';

export const BeneficiariesLayout = () => {
  const { t } = useTranslation();
  const theme = useMantineTheme();

  const { healthPlans, activeUserPlan } = useUserPlansData();

  const groupedPlans: TGroupedPlans =
    handleGroupHealthPlansByCategory(healthPlans);
  const planNameToCheck = activeUserPlan?.plan?.name;

  // return info to tell user to upgrade their plans if they are not in the group or family plan
  if (
    planNameToCheck &&
    !(
      groupedPlans.family.some((plan) => plan.name === planNameToCheck) ||
      groupedPlans.group.some((plan) => plan.name === planNameToCheck)
    )
  ) {
    return (
      <Stack
        sx={{
          ...getPageDefaultStyles(theme),
        }}
        spacing={theme.spacing.xl}
      >
        <AppHeader componentName={t('plans.Beneficiaries')} />
        <UserToUpgradeSubscription
          activity={t('plans.UnableToViewBeneficiariesNotSubscribed')}
        />
      </Stack>
    );
  }

  return <Outlet />;
};
