export interface INotification {
  id: string;
  userId: string;
  subject: string;
  content: string;
  dateCreated: string;
  dateOpened?: string;
  notificationType: string;
  notificationStatus: string;
}

export interface INotificationsResponse {
  notifications: INotification[];
}

export enum ENotificationTypes {
  Appointment = 'appointment',
  Message = 'message',
  Prescription = 'prescription',
  MedicalReviews = 'medical reviews',
}

export enum EUnreadNotificationStatus {
  Active = 'Active',
  Read = 'Read',
}
