import {
  showErrorNotification,
  showSuccessNotification,
} from 'utils/notificationUtils';

export function addSpacingBeforeCapital(text: string) {
  return text.replace(/([A-Z])/g, ' $1').trim();
}

export function handleGetPluralizedWord(
  count: number,
  singular: string,
  plural: string,
) {
  return count <= 1 ? singular : plural;
}

export async function copyToClipboard(text: string) {
  try {
    await navigator.clipboard.writeText(text);
    showSuccessNotification(
      'copied-to-clipboard',
      'Copied to clipboard',
      'Done',
    );
  } catch (e) {
    showErrorNotification(
      'not-copy-to-clip',
      'Cannot copy to clipboard',
      'Alert',
    );
  }
}

export const handleEscapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const handleMatchRouteWithDynamicKey = (
  route: string,
  currentPath: string,
) => {
  const routeWithDynamicKey = route?.replace(/:\w+\*/, '');
  const dynamicKey = currentPath?.replace(routeWithDynamicKey, '');

  const routePattern = new RegExp(
    '^' + route.replace(/:\w+/, handleEscapeRegExp(dynamicKey)) + '$',
  );

  return routePattern.test(currentPath);
};

export const handleCapitalizeFirstChar = (str: string) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return;
};

export function handleFormatNumberWithCommas(input: number | string): string {
  if (typeof input === 'number') {
    return input.toLocaleString();
  } else if (typeof input === 'string') {
    const parsedNumber = parseFloat(input);
    if (!isNaN(parsedNumber)) {
      return parsedNumber.toLocaleString();
    }
  }
  return 'Invalid input';
}

export function areAllFieldsEmpty(obj: any): boolean {
  if (obj === '' || obj === 0 || obj == null) {
    return true;
  }

  if (Array.isArray(obj)) {
    return obj.every(areAllFieldsEmpty);
  }

  if (typeof obj === 'object' && obj !== null) {
    return Object.values(obj).every(areAllFieldsEmpty);
  }

  // If it's a primitive value that is not considered empty, return false
  return false;
}
