import {
  Text,
  Button,
  Box,
  Badge,
  Card,
  createStyles,
  Group,
} from '@mantine/core';
import {
  EUnreadNotificationStatus,
  INotification,
} from 'services/types/notification';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { handleFormatDate } from 'utils';
import { useGetAccountQuery } from 'services/api/account';
import {
  useGetPaginatedNotificationsQuery,
  useUpdateNotificationsMutation,
} from 'services/api/notification';
import { logErrorMessage } from 'hooks/useLogger';
import { showErrorNotification } from 'utils/notificationUtils';

const useStyles = createStyles((theme) => ({
  card: {
    marginBottom: theme.spacing.sm,
    backgroundColor: theme.colorScheme !== 'dark' ? theme.white : '',
    border: `1px solid ${theme.colors.gray[2]}`,
  },
  unreadCard: {
    backgroundColor:
      theme.colorScheme !== 'dark'
        ? theme.colors.gray[1]
        : theme.colors.secondary[9],
  },
  badgeContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  title: {
    marginBottom: theme.spacing.xs,
  },
  timestamp: {
    marginTop: theme.spacing.xs,
  },
  markAllButton: {
    marginBottom: theme.spacing.sm,
  },
}));
export const UnreadNotificationModalContainer = () => {
  const { t } = useTranslation();
  const { data: currentUser } = useGetAccountQuery();
  const { data: { notifications: allNotifications = [] } = {} } =
    useGetPaginatedNotificationsQuery(
      {
        userId: currentUser?.userId ?? '',
        pageNumber: 1,
      },
      { skip: !currentUser?.userId },
    );

  const [pageNumber, setPageNumber] = useState(1);
  const { classes } = useStyles();

  const filteredNotifications = allNotifications?.filter(
    (notification: INotification) =>
      notification.notificationStatus === EUnreadNotificationStatus.Active,
  );

  const sortNotifications = (notifications: INotification[]) => {
    return [...notifications]?.sort(
      (a, b) =>
        new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime(),
    );
  };

  const renderNotifications = (notifications: INotification[]) => {
    if (!notifications || notifications.length === 0) {
      return <Text color="gray">{t('home.NoNewNotifications')}</Text>;
    }

    return notifications?.map((notification: INotification) => (
      <Card
        key={notification.id}
        shadow="sm"
        onClick={() => handleMarkNotificationAsRead(notification.id)}
        className={`${classes.card} ${
          notification.notificationStatus === EUnreadNotificationStatus.Read
            ? ''
            : classes.unreadCard
        }`}
      >
        <Text size="md" weight={500} className={classes.title}>
          {notification.subject}
        </Text>
        <Text size="sm">{notification.content}</Text>
        <Text size="xs" className={classes.timestamp}>
          {handleFormatDate(notification.dateCreated)}
        </Text>
      </Card>
    ));
  };

  const [updateNotification, { isLoading: isUpdateNotificationLoading }] =
    useUpdateNotificationsMutation();

  const handleUpdateNotification = async (data: any) => {
    await updateNotification(data)
      .unwrap()
      .catch((error) => {
        logErrorMessage(error);
        // const errorMessage = error.data?.errors?.[0]?.message  || error.data
        showErrorNotification(
          'notification-error',
          'Something went wrong while updating notification',
        );
      });
  };

  const handleMarkNotificationAsRead = async (id: string) => {
    const updatedNotifications = await Promise.all(
      allNotifications?.map(async (notification: INotification) => {
        if (notification.id === id) {
          if (notification.notificationStatus.toLowerCase() === 'read') {
            return;
          }
          await handleUpdateNotification({ id, read: true });
        }
        return notification;
      }),
    );

    return updatedNotifications;
  };

  const handleMarkAllAsRead = async () => {
    if (filteredNotifications && filteredNotifications?.length === 0) {
      return;
    }
    const updatedNotifications = filteredNotifications.map(
      (notification: INotification) => ({
        ...notification,
        read: true,
      }),
    );

    await Promise.all(
      updatedNotifications.map((notification: INotification) =>
        handleUpdateNotification({ id: notification.id, read: true }),
      ),
    );
  };

  return (
    <Card shadow="sm">
      <Button
        variant="light"
        color="blue"
        fullWidth
        onClick={handleMarkAllAsRead}
        className={classes.markAllButton}
        disabled={filteredNotifications?.length === 0}
      >
        {isUpdateNotificationLoading
          ? 'Marking as read'
          : filteredNotifications?.length === 0
          ? t('home.AllNotificationsRead')
          : t('home.MarkAllAsRead')}
      </Button>
      <Box className={classes.badgeContainer}>
        <Badge
          variant="light"
          color="red"
          size="sm"
          style={{ marginRight: '0.5rem' }}
        >
          {filteredNotifications?.length}
        </Badge>
        <Text size="lg" weight={500}>
          {t('home.Unread')}
        </Text>
      </Box>
      {renderNotifications(sortNotifications(allNotifications))}
      {allNotifications.length > 0 && (
        <Group>
          <Button
            type="button"
            variant="outline"
            radius="sm"
            disabled={pageNumber === 1}
            onClick={() => {
              setPageNumber((prev) => prev - 1);
            }}
          >
            Previous
          </Button>
          <Button
            type="button"
            variant="filled"
            color="cyan"
            radius="sm"
            onClick={() => {
              setPageNumber((prev) => prev + 1);
            }}
          >
            Next
          </Button>
        </Group>
      )}
    </Card>
  );
};
