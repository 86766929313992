import { isToday, isYesterday } from 'utils';
import { useEffect, useRef } from 'react';
import { IMessage } from 'features/AISupport/types';
import { Box, Text, createStyles } from '@mantine/core';
import { BsChat } from 'react-icons/bs';

interface ChatMessagesProps {
  messages: IMessage[];
}
const useStyles = createStyles((theme) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.md,
    background:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    borderRadius: theme.radius.md,
    padding: theme.spacing.md,
    overflowY: 'auto',
    minHeight: '2rem',
    maxHeight: '20rem',
  },
  emptyState: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  groupTitle: {
    textAlign: 'center',
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === 'dark' ? theme.colors.white : theme.colors.gray[7],
    marginBottom: theme.spacing.xs,
    [`@media (min-width: ${theme.breakpoints.md}px)`]: {
      fontSize: theme.fontSizes.md,
    },
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.xs,
    padding: theme.spacing.md,
    borderRadius: theme.radius.sm,
  },
  messageBoxOther: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.radius.sm,
    marginBottom: theme.spacing.xs,
    maxWidth: '50%',
    background:
      theme.colorScheme === 'dark'
        ? theme.colors.secondary[9]
        : theme.colors.gray[1],
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    [theme.fn.smallerThan('md')]: {
      maxWidth: '100%',
    },
  },
  messageBoxUser: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-end',
    maxWidth: '50%',
    background:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[3]
        : theme.colors.secondary[1],
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    borderRadius: theme.radius.sm,
    [theme.fn.smallerThan('md')]: {
      maxWidth: '100%',
    },
  },
  senderInfo: {
    display: 'flex',
    gap: theme.spacing.sm,
    padding: theme.spacing.sm,
  },
  messageContent: {
    padding: theme.fontSizes.sm,
    borderRadius: theme.radius.md,
    marginLeft: theme.spacing.lg,
  },
}));

const groupMessagesByDate = (messages: IMessage[]) => {
  const groupedMessages: { [key: string]: IMessage[] } = {};

  messages.forEach((message) => {
    const messageDate = new Date(message.timestamp);

    let title: string;
    if (isToday(messageDate)) {
      title = 'Today';
    } else if (isYesterday(messageDate)) {
      title = 'Yesterday';
    } else {
      title = messageDate.toLocaleDateString('en-UK', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });
    }

    if (!groupedMessages[title]) {
      groupedMessages[title] = [];
    }
    groupedMessages[title].push(message);
  });

  const groupedArray = Object.entries(groupedMessages).map(
    ([title, messages]) => ({
      title,
      messages,
    }),
  );

  return groupedArray;
};

const ChatMessages = ({ messages }: ChatMessagesProps) => {
  const { classes } = useStyles();
  const groupedMessages = groupMessagesByDate(messages);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <Box className={classes.section} ref={containerRef}>
      {messages.length === 0 && (
        <Box className={classes.emptyState}>
          <Text>Make your enquires here</Text>
        </Box>
      )}
      {groupedMessages.map((group) => {
        const { title, messages } = group;

        return (
          <Box key={title}>
            <Text className={classes.groupTitle}>{title}</Text>

            <Box className={classes.messageContainer}>
              {messages.map((message, index) => {
                const { content, sender, status } = message;
                const isUser = sender === 'user';

                return (
                  <Box
                    key={index}
                    className={
                      isUser ? classes.messageBoxUser : classes.messageBoxOther
                    }
                  >
                    {!isUser && (
                      <Box className={classes.senderInfo}>
                        <BsChat />
                        <Text size="sm">{sender}</Text>
                      </Box>
                    )}

                    <Box className={classes.messageContent}>
                      <Text size="sm">
                        {content}{' '}
                        {status === 'typing' && <span>Typing...</span>}
                      </Text>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default ChatMessages;
