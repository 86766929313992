import {
  Grid,
  Stack,
  Title,
  StackProps,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import { PrescriptionTableContainer } from 'features/Prescriptions/components/PrescriptionTableContainer';
import { useTranslation } from 'react-i18next';
import { getPageDefaultStyles } from 'themes';

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: theme.fontSizes.md * 1.5,
    [theme.fn.smallerThan('md')]: {
      fontSize: theme.fontSizes.lg * 1.1,
    },
  },
}));

export interface PrescriptionsTableProps extends StackProps {}

export const PrescriptionsTablePage = ({
  ...otherProps
}: PrescriptionsTableProps) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Stack
      {...otherProps}
      sx={{
        ...getPageDefaultStyles(theme),
      }}
    >
      <Title order={2} weight={500} className={classes.title}>
        {t('activity.YourPrescription')}
      </Title>

      <Grid gutter={theme.spacing.xl * 3.5}>
        <Grid.Col md={12} lg={12}>
          <PrescriptionTableContainer />
        </Grid.Col>
      </Grid>
    </Stack>
  );
};
