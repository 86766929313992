import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from 'services/api/endpoints';
import { RootState } from 'redux/store';

const staggeredBaseQuery = retry(
  fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).account.authToken;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  {
    maxRetries: 2,
  },
);

// initialize an empty api service that we'll inject endpoints into later as needed
// * Why? - https://github.com/reduxjs/redux-toolkit/issues/1725#issuecomment-1451329094
export const baseApi = createApi({
  baseQuery: staggeredBaseQuery,
  endpoints: () => ({}),
});
