import {
  Avatar,
  Group,
  Modal,
  createStyles,
  Indicator,
  Title,
} from '@mantine/core';
import { RiNotification2Line } from 'react-icons/ri';
import { ModalTitle } from 'components/ModalTitle';
import { Link } from 'react-router-dom';
import {
  EUnreadNotificationStatus,
  INotification,
} from 'services/types/notification';
import { UnreadNotificationModalContainer } from 'features/Notifications/components/UnreadNotificationModalContainer';
import { useDisclosure } from '@mantine/hooks';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { APP_ROUTES_LIST } from 'types';
import { useGetAccountQuery } from 'services/api/account';
import { useGetPaginatedNotificationsQuery } from 'services/api/notification';

const useStyles = createStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing.md,
  },
  iconWrapper: {
    width: 40,
    height: '40px',

    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.colors.secondary[9]
        : theme.colors.gray[0],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '20px',
    cursor: 'pointer',
  },
}));

interface AppHeaderProps {
  componentName?: string;
}

export const AppHeader = ({ componentName = '' }: AppHeaderProps) => {
  const { classes } = useStyles();

  const { data: currentUser } = useGetAccountQuery();
  const { data: { notifications: allNotifications = [] } = {} } =
    useGetPaginatedNotificationsQuery(
      {
        userId: currentUser?.userId ?? '',
        pageNumber: 1,
      },
      { skip: !currentUser?.userId },
    );
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Group className={classes.root}>
        <Group>
          {componentName && (
            <>
              <MdKeyboardArrowLeft
                size={40}
                onClick={() => {
                  window.history.back();
                }}
                cursor="pointer"
              />
              <Title weight={500} order={2}>
                {componentName}
              </Title>
            </>
          )}
        </Group>

        <Group>
          <Indicator
            color="red"
            offset={4}
            className={classes.iconWrapper}
            disabled={
              allNotifications?.filter(
                (notification: INotification) =>
                  notification.notificationStatus ===
                  EUnreadNotificationStatus.Active,
              )?.length === 0 || allNotifications?.length === 0
            }
          >
            <RiNotification2Line size={21} onClick={open} />
          </Indicator>
          <Modal
            opened={opened}
            size={'35rem'}
            withCloseButton
            onClose={close}
            overflow="inside"
            title={<ModalTitle title="Notifications" />}
          >
            <UnreadNotificationModalContainer />
          </Modal>
          <Link to={APP_ROUTES_LIST.ACCOUNT}>
            <Avatar
              radius={'xl'}
              size={40}
              src={`${currentUser?.avatar}?v=${Date.now()}`}
              alt="profile image"
            />
          </Link>
        </Group>
      </Group>
    </>
  );
};
