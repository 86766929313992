import { createSlice } from '@reduxjs/toolkit';
interface AccountState {
  userEmail: string;
  authToken: string;
  currentUser: any;
  searchValue: string;
  registerData: any;
}

const initialState = {
  userEmail: '',
  authToken: '',
  currentUser: null,
  registerData: {},
} as AccountState;

const accountSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setRegisterData: (state, action) => {
      state.registerData = action.payload;
    },
  },
});

export const { setUserEmail, setAuthToken, setCurrentUser, setRegisterData } =
  accountSlice.actions;

export default accountSlice.reducer;
