import { IHealthPlan, IPlanDuration } from 'services/types/plan';

interface PlanDetails {
  planId: string;
  plan: IHealthPlan;
  planDuration: IPlanDuration;
  startDate: string;
  endDate: string;
  createdDate: string;
  modifiedDate: string;
  paymentStatus: number;
  status: boolean;
}

interface IPlanBeneficiaryDto {
  id: string;
  plan: PlanDetails;
  firstName: string;
  lastName: string;
  middleName: string;
  fullName: string;
  suffix: string;
  createdDate: string;
  status: boolean;
  isParentBeneficiary: boolean;
  beneficiaryNumber: string;
  gender: number;
  dateOfBirth: string;
  relationship: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  phoneNumber: string;
  email: string;
}

export interface IAppointment {
  id: string;
  planBeneficiaryDto: IPlanBeneficiaryDto;
  professionalId: string;
  appointmentStatus: string;
  appointmentFormat: string;
  appointmentDate: string;
  cancellationReason: string;
  symptoms: string;
  additionalInfo: string;
  beneficiaryRemindDate: string;
  professionalRemindDate: string;
  professionalName: string;
}

export interface IAppointmentsResponse {
  appointments: IAppointment[];
}

export interface IGetAppointmentRequest {
  beneficiaryId: string;
  status: number;
  pageNumber: number;
  pageSize?: number;
}
export interface IBookAppointmentRequest {
  planBeneficiaryId: string;
  professionalId: string;
  appointmentStatus: number;
  appointmentFormat: number;
  appointmentDate: string;
  cancellationReason: string;
  symptoms: string;
  additionalInfo: string;
  beneficiaryRemindDate: string;
  professionalRemindDate: string;
}
export interface IAppointmentReviewRequest {
  appointmentId: string;
  rating: number;
  review?: string;
  dateAdded: string;
  planBeneficiaryId: string;
}

export interface IBookAppointmentFormValues {
  date: Date;
  time: Date;
  format: string;
  symptoms: string[];
  additionalInfo: string;
}
export interface ISymptons {}

export enum EIllnessSymptoms {
  Fever,
  Cough,
  SoreThroat,
  Headache,
  Fatigue,
}

export enum AppointmentStatus {
  Pending = 1,
  Completed = 4,
  Declined = 3,
  Scheduled = 2,
}

export enum AppointmentTabStatus {
  Pending = 'Pending',
  Completed = 'Completed',
  Declined = 'Declined',
  Scheduled = 'Scheduled',
}

export type AppointmentSelectedTabTypes =
  | AppointmentTabStatus.Scheduled
  | AppointmentTabStatus.Pending
  | AppointmentTabStatus.Declined
  | AppointmentTabStatus.Completed;

export enum EBookAppointmentSource {
  BookAppointment = 'book-appointment',
  SearchProfessionals = 'search-professionals',
}
