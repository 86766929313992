import { createSlice } from '@reduxjs/toolkit';
import { IHealthPlan, IPlanDuration } from 'services/types/plan';
interface planState {
  currentPlanSection: string;
  selectedPlanDurationForSubscription: IPlanDuration;
  selectedHealthPlanForSubscription: IHealthPlan;
  paymentReferenceSubscriptionData: any;
  selectedBeneficiaryForEditing: any;
}

const initialState: planState = {
  currentPlanSection: '',
  // * important - The initialization so that if a user doesn't choose the plan duration in the confirm-subscriptionn route, it sets the value of the tab to be this
  selectedPlanDurationForSubscription: {
    description: '1 Month',
    id: 1,
    name: 'Monthly',
  },
  // * important - The initialization is cos of the freemium we have
  selectedHealthPlanForSubscription: {
    description: 'Free Plan',
    extraPersonPrice: 0,
    id: 6,
    maxPerson: 1,
    name: 'Freemium',
    price: 0,
  },
  paymentReferenceSubscriptionData: {} as any,
  selectedBeneficiaryForEditing: {} as any,
};

const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    setCurrentPlanSection: (state, action) => {
      state.currentPlanSection = action.payload;
    },
    setSelectedPlanDurationForSubscription: (state, action) => {
      state.selectedPlanDurationForSubscription = action.payload;
    },
    setSelectedHealthPlanForSubsctiption: (state, action) => {
      state.selectedHealthPlanForSubscription = action.payload;
    },
    setPaymentReferenceSubscriptionData: (state, action) => {
      state.paymentReferenceSubscriptionData = action.payload;
    },
    setSelectedBeneficiaryForEditing: (state, action) => {
      state.selectedBeneficiaryForEditing = action.payload;
    },
  },
});

export const {
  setCurrentPlanSection,
  setSelectedPlanDurationForSubscription,
  setSelectedHealthPlanForSubsctiption,
  setPaymentReferenceSubscriptionData,
  setSelectedBeneficiaryForEditing,
} = planSlice.actions;
export default planSlice.reducer;
