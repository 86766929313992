import {
  useGetActivePlansByUserIdQuery,
  useGetHealthAndPlanDurationsQuery,
  useGetPlanBeneficiariesByUserPlanIdQuery,
} from 'services/api/plan';
import { useGetAccountQuery } from 'services/api/account';

export const useUserPlansData = () => {
  // Fetch current user
  const { data: currentUser } = useGetAccountQuery();

  // Fetch health plans
  const {
    data: { healthPlans = [], planDurations = [] } = {},
    isLoading: isHealthAndPlanDurationsLoading,
  } = useGetHealthAndPlanDurationsQuery();

  // Fetch active user plans
  const {
    data: { userPlans: activeUserPlans = [] } = {},
    isLoading: isActiveUserPlansLoading,
  } = useGetActivePlansByUserIdQuery(
    { userId: currentUser?.userId ?? '' },
    { skip: !currentUser?.userId },
  );

  // Get the first active plan
  const activeUserPlan = activeUserPlans?.[0];

  // Fetch plan beneficiaries based on active user plan ID
  const {
    data: { planBeneficiaries = [] } = {},
    isLoading: isPlanBeneficiariesLoading,
  } = useGetPlanBeneficiariesByUserPlanIdQuery(
    { userPlanId: activeUserPlan?.planId ?? '' },
    { skip: !activeUserPlan?.planId },
  );

  const currentUserPlanBeneficiaryId = planBeneficiaries?.[0]?.id || null;

  return {
    healthPlans,
    activeUserPlans,
    activeUserPlan,
    planDurations,
    planBeneficiaries,
    currentUserPlanBeneficiaryId,
    isHealthAndPlanDurationsLoading,
    isActiveUserPlansLoading,
    isPlanBeneficiariesLoading,
  };
};
