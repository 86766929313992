import { useOidcIdToken } from '@axa-fr/react-oidc';
import { useEffect } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { Loading } from 'components/Loading';
import { Stack } from '@mantine/core';
import { setAuthToken, setCurrentUser } from 'redux/accountSlice';
import { useGetAccountQuery } from 'services/api/account';

export const OidcAuthSuccess = () => {
  const { idToken } = useOidcIdToken();
  const dispatch = useAppDispatch();
  const { data: currentUser, isLoading } = useGetAccountQuery();

  useEffect(() => {
    if (currentUser?.userId) {
      dispatch(setCurrentUser(currentUser));
    }
    dispatch(setAuthToken(idToken));
  }, [currentUser?.userId, idToken]);

  return (
    <Stack align="center">
      <Loading
        size="xl"
        variant="bars"
        text={
          isLoading
            ? 'Fetching your details, please hold on...'
            : 'Authentication successful. You will be redirected to the app'
        }
        height="20vh"
      />
    </Stack>
  );
};
