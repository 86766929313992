import { ENDPOINTS } from 'services/api/endpoints';
import { baseApi } from 'redux/baseApiSlice';
import {
  IAppointmentReviewRequest,
  IAppointmentsResponse,
  IBookAppointmentRequest,
  IGetAppointmentRequest,
} from 'services/types/appointment';

const appointmentApiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: ['Appointments', 'Meeting'],
});

export const appointmentApi = appointmentApiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getUsersAppointmentByStatusDetails: builder.query<
      IAppointmentsResponse,
      IGetAppointmentRequest
    >({
      query: ({ beneficiaryId, status, pageNumber = 1, pageSize = 200 }) => ({
        url: `/${ENDPOINTS.APPOINTMENT}/by-status/${beneficiaryId}?status=${status}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
        method: 'GET',
      }),
      providesTags: ['Appointments'],
    }),

    bookAppointment: builder.mutation<void, { data: IBookAppointmentRequest }>({
      query: ({ data }) => ({
        url: `/${ENDPOINTS.APPOINTMENT}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Appointments'],
    }),
    addAppointmentReview: builder.mutation<
      void,
      { data: IAppointmentReviewRequest }
    >({
      query: ({ data }) => ({
        url: `/${ENDPOINTS.APPOINTMENT}/review`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Appointments'],
    }),
    generateMeetingJwt: builder.mutation<object, any>({
      query: (data) => ({
        url: `/${ENDPOINTS.MEETING}/jitsi`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Meeting'],
    }),
  }),
});

export const {
  useGetUsersAppointmentByStatusDetailsQuery,
  useBookAppointmentMutation,
  useAddAppointmentReviewMutation,
  useGenerateMeetingJwtMutation,
} = appointmentApi;
