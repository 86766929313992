import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
} from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { NotificationsProvider } from '@mantine/notifications';
import { useState } from 'react';
import { Provider } from 'react-redux';
import { persistor, store } from 'redux/store';
import { OidcProvider } from '@axa-fr/react-oidc';
import { Loading } from 'components/Loading';
import { themeOverride } from 'themes';
import { PersistGate } from 'redux-persist/integration/react';
import { OidcAuthSuccess } from 'components/Oidc/OidcAuthSuccess';
import OidcAuthError from 'components/Oidc/OidcAuthError';

// This configuration use the ServiceWorker mode only
// "access_token" will be provided automaticaly to the urls and domains configured inside "OidcTrustedDomains.js"
const configuration = {
  client_id:
    process.env.NODE_ENV === 'production'
      ? 'react_client_patient'
      : 'react_client_dev',
  redirect_uri: window.location.origin + '/authentication/callback',
  silent_redirect_uri:
    window.location.origin + '/authentication/silent-callback', // Optional activate silent-signin that use cookies between OIDC server and client javascript to restore the session
  scope: 'openid profile offline_access',
  authority: 'https://id.homnics.com',
  service_worker_relative_url: '/OidcServiceWorker.js',
  service_worker_only: true,
};

export interface ThemeProviderProps {
  children: React.ReactNode;
}

export const AppProvider = ({ children }: ThemeProviderProps) => {
  const [colorScheme, setColorScheme] = useState<ColorScheme>('light');
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));
  const dark = colorScheme === 'dark';
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ColorSchemeProvider
          colorScheme={colorScheme}
          toggleColorScheme={toggleColorScheme}
        >
          <MantineProvider
            theme={
              dark ? { ...themeOverride, colorScheme: 'dark' } : themeOverride
            }
            withGlobalStyles
            withNormalizeCSS
          >
            <ModalsProvider labels={{ confirm: 'Submit', cancel: 'Cancel' }}>
              <NotificationsProvider zIndex={10000} position="bottom-center">
                <OidcProvider
                  configuration={configuration}
                  loadingComponent={() => (
                    <Loading
                      size="xl"
                      variant="bars"
                      text="Loading..."
                      height="30vh"
                    />
                  )}
                  authenticatingComponent={() => (
                    <Loading
                      size="xl"
                      variant="bars"
                      text="Authenticating..."
                      height="30vh"
                    />
                  )}
                  callbackSuccessComponent={OidcAuthSuccess}
                  sessionLostComponent={() => (
                    <OidcAuthError message="Your current session is lost" />
                  )}
                  authenticatingErrorComponent={OidcAuthError}
                >
                  {children}{' '}
                </OidcProvider>
              </NotificationsProvider>
            </ModalsProvider>
          </MantineProvider>
        </ColorSchemeProvider>
      </PersistGate>
    </Provider>
  );
};
