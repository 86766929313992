import { useOidc, useOidcIdToken } from '@axa-fr/react-oidc';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';
import { useGetAccountQuery } from 'services/api/account';
import { setAuthToken, setCurrentUser } from 'redux/accountSlice';
import useLogout from 'hooks/useLogout';

interface PrivateRouteProps {
  allowedRoles: string[];
}

export const PrivateRoute = ({ allowedRoles }: PrivateRouteProps) => {
  const location = useLocation();
  const authToken = localStorage.getItem('hm.authToken');
  const navigate = useNavigate();
  const { logout, isAuthenticated } = useOidc();
  const { handleLogout } = useLogout();
  const { data: currentUser } = useGetAccountQuery();
  const dispatch = useAppDispatch();

  const { idToken } = useOidcIdToken();

  useEffect(() => {
    if (currentUser?.userId) {
      dispatch(setCurrentUser(currentUser));
    }
    dispatch(setAuthToken(idToken));
  }, [currentUser?.userId, idToken]);

  useEffect(() => {
    const fetchData = async () => {
      if (!isAuthenticated) {
        logout('/');
        return;
      }

      if (currentUser && currentUser?.roles) {
        if (
          !currentUser.roles.some((role: string) => allowedRoles.includes(role))
        ) {
          // todo - Automatically navigate to the professional website if the role is not user
          return navigate('/unauthorized', {
            state: {
              from: location,
            },
            replace: true,
          });
        }
        return;
      }
    };

    fetchData();
  }, [authToken, currentUser, allowedRoles, navigate, location]);

  if (!isAuthenticated) {
    handleLogout();
  }

  return <Outlet />;
};
