import { UserRoles } from 'types/AppTypes';

const AdminRoles = [
  UserRoles.Admin?.toString(),
  UserRoles.SuperAdmin?.toString(),
  UserRoles.Support?.toString(),
  UserRoles.System?.toString(),
];

export const isAdmin = (roles: string[]) => {
  if (!roles?.length) return false;
  return roles?.some((role: string) => AdminRoles.includes(role));
};
