import { useOidc } from '@axa-fr/react-oidc';
import {
  Text,
  Stack,
  Box,
  Button,
  Loader,
  createStyles,
  LoaderProps,
} from '@mantine/core';
import { ReactNode } from 'react';
import { APP_ROUTES_LIST } from 'types';

export const useLoaderStyles = createStyles(() => ({
  loader: {
    width: '100%',
    height: '100%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  btn: {
    borderRadius: 8,
  },
}));

interface LoadingProps extends LoaderProps {
  text?: string;
  children?: ReactNode;
  link?: boolean;
}

export const Loading = ({
  text,
  link = false,
  ...otherProps
}: LoadingProps) => {
  const { classes } = useLoaderStyles();
  const { login } = useOidc();

  return (
    <Stack spacing={2} className={classes.loader}>
      <Loader {...otherProps} />
      <Text className={classes.text}>{text}</Text>

      {link && (
        <Box mt={20} className={classes.btnContainer}>
          <Button
            color="#008CB3"
            radius="md"
            size="md"
            className={classes.btn}
            onClick={() => {
              login(APP_ROUTES_LIST.HOME);
            }}
          >
            Continue
          </Button>
        </Box>
      )}
    </Stack>
  );
};
