import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  persistReducer,
  persistStore,
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import { setupListeners } from '@reduxjs/toolkit/query';
import appReducer from 'redux/appSlice';
import accountReducer from 'redux/accountSlice';
import planReducer from 'redux/planSlice';
import appointmentReducer from 'redux/appointmentSlice';
import prescriptionReducer from 'redux/prescriptionSlice';
import { baseApi } from 'redux/baseApiSlice';
import { countryApi } from 'services/api/country';
import { RESET_STORE } from 'types';

const persistConfig = {
  key: 'root',
  storage: storage,
};

const rootReducer = combineReducers({
  app: appReducer,
  account: accountReducer,
  plan: planReducer,
  appointment: appointmentReducer,
  prescription: prescriptionReducer,
  // record: recordReducer,
  [baseApi.reducerPath]: baseApi.reducer,
  [countryApi.reducerPath]: countryApi.reducer,
});

// // @ts-ignore,
// @ts-expect-error - the state and action induces type errors
const resettableRootReducer = (state, action) => {
  if (action.type === RESET_STORE) {
    storage.removeItem('persist:root');
    return rootReducer(undefined, action);
  }

  return rootReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, resettableRootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      immutableCheck: process.env.NODE_ENV === 'production' ? true : false,
    }).concat(baseApi.middleware, countryApi.middleware),
});

setupListeners(store.dispatch);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export type AppRootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
