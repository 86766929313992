import { useState } from 'react';
import { UserStatuses } from 'types';
import { useAppSelector } from 'redux/hooks';
import { AppointmentTabStatus, IAppointment } from 'services/types/appointment';

export const useAppointmentTabs = () => {
  const { searchValue } = useAppSelector((state) => state.app);

  //  todo - check whatever convulated filter is going on here
  const [selectedTab] = useState<AppointmentTabStatus>(
    AppointmentTabStatus.Pending,
  );

  const filteredAppointmentsArray = (
    selectedTabAppointmentsArray: IAppointment[],
  ) => {
    if (!selectedTabAppointmentsArray) {
      return [];
    }

    return selectedTabAppointmentsArray
      ?.sort(
        (a: IAppointment, b: IAppointment) =>
          new Date(b.appointmentDate).getTime() -
          new Date(a.appointmentDate).getTime(),
      )
      ?.filter((appointment: IAppointment) => {
        const { appointmentFormat, professionalName, appointmentDate } =
          appointment;
        const fullName = `${appointment?.planBeneficiaryDto?.firstName} ${appointment?.planBeneficiaryDto?.lastName}`;
        return (
          appointment.appointmentStatus === selectedTab &&
          (fullName?.toLowerCase().includes(searchValue.toLowerCase()) ||
            appointmentFormat
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            professionalName
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            appointmentDate.toLowerCase().includes(searchValue.toLowerCase()))
        );
      });
  };

  const filteredAndSortedAppointmentsArray = (
    appointments: IAppointment[],
  ): IAppointment[] => {
    return appointments?.filter((appointment: IAppointment) => {
      const today = new Date();
      const appointmentDate = new Date(appointment.appointmentDate);

      if (
        appointment.appointmentStatus === UserStatuses.Completed ||
        appointment.appointmentStatus === UserStatuses.Declined
      ) {
        return true;
      }

      if (
        appointment.appointmentStatus === UserStatuses.Scheduled ||
        appointment.appointmentStatus === UserStatuses.Pending
      ) {
        return appointmentDate >= today;
      }

      return false;
    });
  };

  return {
    selectedTab,
    searchValue,
    filteredAppointmentsArray,
    filteredAndSortedAppointmentsArray,
  };
};
