import { ENDPOINTS } from 'services/api/endpoints';
import { baseApi } from 'redux/baseApiSlice';
import {
  IAvailableProfessionalParams,
  IProfessionalResponse,
  IProfessionalReviewRequest,
  IProfessionalReviewsResponse,
  IProfessionalTypesResponse,
} from 'services/types/professional';

const professionalApiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [
    'Professional_Details',
    'Professional_Types',
    'All_Professionals',
    'Available_Professionals',
    'Professional_Reviews',
    'Medical_Documents',
  ],
});

export const professionalApi = professionalApiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getAllProfessionals: builder.query<IProfessionalResponse, void>({
      query: () => ({
        url: `/${ENDPOINTS.PROFESSIONAL}/all?pageSize=100`,
        method: 'GET',
      }),
      providesTags: ['All_Professionals'],
    }),
    getAvailableProfessionals: builder.query<
      IProfessionalResponse,
      IAvailableProfessionalParams
    >({
      query: ({ appointmentDate }) => ({
        url: `/${ENDPOINTS.PROFESSIONAL}/all?pageSize=100&appointmentDate=${appointmentDate}`,
        method: 'GET',
      }),
      providesTags: ['Available_Professionals'],
    }),

    addProfessionalReview: builder.mutation<
      void,
      { data: IProfessionalReviewRequest }
    >({
      query: ({ data }) => ({
        url: `${ENDPOINTS.PROFESSIONAL}/review`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['All_Professionals', 'Professional_Reviews'],
    }),
    getProfessionalReviewsByBeneficiaryId: builder.query<
      IProfessionalReviewsResponse,
      { beneficiaryId: string }
    >({
      query: ({ beneficiaryId }) => ({
        url: `/${ENDPOINTS.PROFESSIONAL}/reviews/${beneficiaryId}/by-planBeneficiary`, // todo - update the reviews route to reflect this
        method: 'GET',
      }),
      providesTags: ['Professional_Reviews'],
    }),
    getAllProfessionalTypes: builder.query<IProfessionalTypesResponse, void>({
      query: () => ({
        url: `/${ENDPOINTS.PROFESSIONAL}/type/all`,
        method: 'GET',
      }),
      providesTags: ['Professional_Types'],
    }),
  }),
});

export const {
  useGetAllProfessionalsQuery,
  useGetAvailableProfessionalsQuery,
  useGetAllProfessionalTypesQuery,
  useAddProfessionalReviewMutation,
  useGetProfessionalReviewsByBeneficiaryIdQuery,
} = professionalApi;
