import { IHealthPlan } from 'services/types/plan';

export function handleGroupHealthPlansByCategory(plans: IHealthPlan[]) {
  const groupedPlans = {
    individual: [] as IHealthPlan[],
    family: [] as IHealthPlan[],
    group: [] as IHealthPlan[],
    freemium: [] as IHealthPlan[],
  };

  plans.forEach((plan) => {
    if (plan.price === 0) {
      groupedPlans.freemium.push(plan);
    } else if (plan.maxPerson === 4) {
      groupedPlans.family.push(plan);
    } else if (plan.maxPerson === 20) {
      groupedPlans.group.push(plan);
    } else if (plan.maxPerson === 1) {
      groupedPlans.individual.push(plan);
    }
  });

  return groupedPlans;
}

export function handleExtractSingleDigitFromString(value: string): number {
  // Use a regular expression to find the digit(s) in the string
  const digitMatch = value?.match(/\d+/);

  if (digitMatch !== null && digitMatch?.length > 0) {
    // If a digit is found, convert it to a number using parseInt
    const digit = parseInt(digitMatch[0], 10);
    return digit;
  }

  // If no digit is found, you can return a default value or handle the case accordingly.
  // Here, I'm returning 0, but you can choose an appropriate default value.
  return 0;
}
