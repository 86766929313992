import { ENDPOINTS } from 'services/api/endpoints';
import { baseApi } from 'redux/baseApiSlice';
import { INotificationsResponse } from 'services/types/notification';

const notificationApiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: ['Notifications'],
});

export const notificationApi = notificationApiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getPaginatedNotifications: builder.query<
      INotificationsResponse,
      { userId: string; pageNumber?: number }
    >({
      query: ({ userId, pageNumber = 1 }) =>
        `/${ENDPOINTS.NOTIFICATION}/${userId}?pageNumber=${pageNumber}`,
      keepUnusedDataFor: 5,
      providesTags: ['Notifications'],
    }),

    updateNotifications: builder.mutation({
      query: (data) => ({
        url: `/${ENDPOINTS.NOTIFICATION}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Notifications'],
    }),
  }),
});

export const {
  useGetPaginatedNotificationsQuery,
  useUpdateNotificationsMutation,
} = notificationApi;
