import { ENDPOINTS } from 'services/api/endpoints';
import { baseApi } from 'redux/baseApiSlice';
import {
  IUser,
  IUtilityGendersResponse,
  IUtilityRelationshipsResponse,
  IUtilityOptionsResponse,
} from 'services/types/account';

const accountApiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [
    'User',
    'Waitlist',
    'Forgot_Password',
    'Notification_Preferences',
    'Gender_List',
    'Relationship_List',
    'User_Profile',
    'Marital_Status',
  ],
});

export const accountApi = accountApiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getAccount: builder.query<IUser, void>({
      query: () => ({
        url: `/${ENDPOINTS.ACCOUNT}/current-user`,
        method: 'GET',
      }),
      providesTags: ['User'],
    }),
    createAccount: builder.mutation({
      query: (data) => ({
        url: `/${ENDPOINTS.ACCOUNT}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['User'],
    }),
    confirmEmail: builder.mutation<
      void,
      { userId: string; mobileToken: string }
    >({
      query: ({ userId, mobileToken }) => ({
        url: `/${ENDPOINTS.ACCOUNT}/${userId}/confirm-email?mobileToken=${mobileToken}`,
        method: 'POST',
      }),
      invalidatesTags: ['User'],
    }),
    getPasswordToken: builder.query({
      query: (email) => ({
        url: `/${ENDPOINTS.ACCOUNT}/reset-password-token?email=${email}`,
        method: 'GET',
        responseHandler: (response) => response.text(),
      }),
      providesTags: ['Forgot_Password'],
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: `/${ENDPOINTS.ACCOUNT}/reset-password`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['User'],
    }),

    // Change Password in App
    changePasswordInApp: builder.mutation({
      query: ({ data, userId }: { data: object; userId: string }) => ({
        url: `/${ENDPOINTS.ACCOUNT}/${userId}/password`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['User'],
    }),

    // Upload Avatar
    uploadAvatar: builder.mutation({
      query: ({ data, userId }: { data: object; userId: string }) => ({
        url: `/${ENDPOINTS.ACCOUNT}/${userId}/avatar`,
        method: 'POST',
        headers: {
          // Don't set Content-Type, let the browser set it automatically.
        },
        body: data,
        formData: true,
      }),
      invalidatesTags: ['User'],
    }),

    // Get Gender List
    getGenderList: builder.query<IUtilityGendersResponse, void>({
      query: () => ({
        url: `/${ENDPOINTS.ACCOUNT}/gender-list`,
        method: 'GET',
      }),
      providesTags: ['Gender_List'],
    }),

    getRelationshipList: builder.query<IUtilityRelationshipsResponse, void>({
      query: () => ({
        url: `/${ENDPOINTS.ACCOUNT}/relationship-list`,
        method: 'GET',
      }),
      providesTags: ['Relationship_List'],
    }),

    getMaritalStatusList: builder.query<IUtilityOptionsResponse, void>({
      query: () => ({
        url: `/${ENDPOINTS.ACCOUNT}/marital-status`,
        method: 'GET',
      }),
      providesTags: ['Marital_Status'],
    }),

    // Update Current User Profile
    updateCurrentUserProfile: builder.mutation({
      query: ({ data, userId }: { data: object; userId: string }) => ({
        url: `/${ENDPOINTS.ACCOUNT}/${userId}/profile`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['User_Profile'],
    }),
  }),
});

export const {
  useGetAccountQuery,
  useCreateAccountMutation,
  useConfirmEmailMutation,
  useGetPasswordTokenQuery,
  useResetPasswordMutation,
  useChangePasswordInAppMutation,
  useUploadAvatarMutation,
  useGetGenderListQuery,
  useGetRelationshipListQuery,
  useGetMaritalStatusListQuery,
  useUpdateCurrentUserProfileMutation,
} = accountApi;
