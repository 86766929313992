import {
  Stack,
  Group,
  Text,
  Indicator,
  Modal,
  Button,
  Divider,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import { Card } from 'components/Card';
import { APP_ROUTES_LIST, UserStatuses } from 'types';
import { AppointmentReviewsModal } from 'features/Appointments/components/AppointmentReviewsModal';
import { useAppointmentItem } from 'features/Appointments/hooks/useAppointmentItem';
import { ModalTitle } from 'components/ModalTitle';
import { handleFormatDate } from 'utils';
import { BiCalendar } from 'react-icons/bi';
import { useDisclosure } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { IAppointment } from 'services/types/appointment';
import { useGenerateMeetingJwtMutation } from 'services/api/appointment';
import { setJwtMeetingDetails } from 'redux/appointmentSlice';
import { logErrorMessage } from 'hooks/useLogger';
import { showErrorNotification } from 'utils/notificationUtils';
import { useNavigate } from 'react-router';
import { useAppDispatch } from 'redux/hooks';

const useStyles = createStyles((theme) => ({
  root: {
    background: theme.colorScheme === 'dark' ? theme.colors.dark[5] : '',
    color: theme.colorScheme === 'dark' ? theme.white : '',
  },
  iconWrapper: {
    border: `3px solid ${theme.colors.gray[4]}`,
    borderRadius: '50%',
    padding: theme.fontSizes.sm * 0.7,
  },
  declined: {
    opacity: 0.8,
    zIndex: 0,
    background: theme.colorScheme === 'dark' ? theme.colors.dark[5] : '',
    color: theme.colorScheme === 'dark' ? theme.white : '',
  },
}));

interface AppointmentItemProps {
  appointment: IAppointment;
}

export const AppointmentItem = ({ appointment }: AppointmentItemProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { appointmentDate, symptoms, appointmentStatus, id, professionalName } =
    appointment;
  const { isWithin30Minutes } = useAppointmentItem();
  const isPending = appointmentStatus === UserStatuses.Pending;
  const isScheduled = appointmentStatus === UserStatuses.Scheduled;
  const isDeclined = appointmentStatus === UserStatuses.Declined;
  const isCompleted = appointmentStatus === UserStatuses.Completed;

  const [
    reviewModalOpened,
    { open: openReviewModal, close: closeReviewModal },
  ] = useDisclosure(false);

  const [generateMeetingJwt, { isLoading: isGenerateMeetingIdLoading }] =
    useGenerateMeetingJwtMutation();

  const handleStartMeeting = async (id: string) => {
    await generateMeetingJwt({ appointmentId: id })
      .unwrap()
      .then((data) => {
        dispatch(setJwtMeetingDetails(data));
        navigate(APP_ROUTES_LIST.MEET);
      })
      .catch((error) => {
        logErrorMessage(error);
        // const errorMessage = error.data?.errors?.[0]?.message  || error.data
        showErrorNotification(
          'generate-meetingid-error',
          'Something went wrong with your request',
        );
      });
  };

  return (
    <>
      <Card className={isDeclined ? classes.declined : classes.root}>
        <Group py={'sm'} position={'apart'}>
          <Group spacing={'xl'}>
            <Indicator color="blue" position="bottom-end" offset={10}>
              <Stack align="center" className={classes.iconWrapper}>
                <BiCalendar color={theme.colors.secondary[5]} size={25} />{' '}
              </Stack>
            </Indicator>

            <Stack spacing={3}>
              <Text weight={600}>
                {isCompleted
                  ? `${t('activity.YouHave')} Completed ${t(
                      'activity.AnAppointment',
                    )}`
                  : isScheduled
                  ? `${t('activity.YouHave')} a Scheduled appointment`
                  : isDeclined
                  ? `${t('activity.YourAppointmentWas')} Declined`
                  : `${t('activity.YourAppointmentIs')} Pending`}{' '}
              </Text>

              <Text weight={500}>
                {t('activity.With')} {professionalName}
              </Text>
              <Text size={14} weight={500}>
                {handleFormatDate(appointmentDate as string)}
              </Text>
              {isPending && (
                <Text size={14} weight={500}>
                  {symptoms?.slice(0, 25)}
                </Text>
              )}
            </Stack>
          </Group>

          {isWithin30Minutes(appointmentDate) && isScheduled && (
            <Button
              radius={'md'}
              color="green"
              variant="outline"
              onClick={() => handleStartMeeting(id)}
              loading={isGenerateMeetingIdLoading}
            >
              {t('activity.StartMeeting')}
            </Button>
          )}
          {isCompleted && (
            <Button
              radius={'md'}
              color="blue"
              variant="outline"
              onClick={() => {
                openReviewModal();
                // dispatch(
                //   handleSelectedAppointmentToReviewInRedux(appointmentProps),
                // );
                // setappointmentDateout(() => {
                //   navigateTo(APP_ROUTES_LIST.REVIEW_DETAIL);
                // }, 500);
              }}
            >
              {t('activity.AddReview')}
            </Button>
          )}
        </Group>
      </Card>
      <Divider />
      <Modal
        opened={reviewModalOpened}
        radius="md"
        onClose={closeReviewModal}
        size={'30rem'}
        title={<ModalTitle title="Review" />}
      >
        <AppointmentReviewsModal
          appointment={appointment}
          closeModal={closeReviewModal}
        />
      </Modal>
    </>
  );
};
