import { AlertFullPage } from 'components/AlertFullPage';
import { PatientPrescriptionTable } from 'features/Prescriptions/components/PatientPrescriptionTable';
import { ProfessionalDataSignature } from 'features/Prescriptions/components/ProfessionalDataSignature';
import {
  Grid,
  createStyles,
  useMantineTheme,
  Box,
  Group,
  Stack,
  Text,
  Button,
  Title,
} from '@mantine/core';
import React from 'react';
import { handleFormatDateWithoutTime } from 'utils';
import { IconBrandLogo } from 'icons';
import { useParams } from 'react-router';
import { usePDF } from 'react-to-pdf';
import { IPrescription } from 'services/types/prescription';
import { useGetPrescriptionsByBeneficiaryIdQuery } from 'services/api/prescription';
import { useTranslation } from 'react-i18next';
import { SkeletonComponent } from 'components/SkeletonComponent';
import { useUserPlansData } from 'features/Subscription/hooks/useUserPlansData';

export const usePrescriptionContainerStyles = createStyles((theme) => ({
  root: {
    boxShadow:
      theme.colorScheme === 'dark'
        ? '0 0  2.6px #fff '
        : '0px 4px 4px rgba(0, 0, 0, 0.5)',
    paddingTop: theme.fontSizes.md,
    paddingBottom: theme.fontSizes.md,
    paddingLeft: theme.fontSizes.md * 1.1,
    paddingRight: theme.fontSizes.md * 1.1,
    borderRadius: theme.fontSizes.md,
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'right',
  },
  logo: {
    fontSize: theme.fontSizes.xl * 3,
    fontWeight: 'bold',
    [theme.fn.smallerThan('lg')]: {
      display: 'none',
    },
  },
  body: {
    background:
      theme.colorScheme === 'dark'
        ? theme.colors.secondary[9]
        : theme.colors.secondary[0],
    paddingTop: theme.fontSizes.md,
    paddingBottom: theme.fontSizes.md,
    paddingLeft: theme.fontSizes.md * 1.9,
    paddingRight: theme.fontSizes.md * 1.9,
    borderRadius: theme.fontSizes.md,
    [theme.fn.smallerThan('lg')]: {
      padding: theme.fontSizes.sm * 0.5,
    },
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  btn: {
    marginTop: 16,
    width: '100%',
  },
  loadingBtnContainer: {
    position: 'relative',
    width: `${theme.fontSizes.md}%`,
    padding: theme.fontSizes.md,
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));
export const PrescriptionTableContainer = () => {
  const { classes } = usePrescriptionContainerStyles();
  const theme = useMantineTheme();
  const { t } = useTranslation();
  const { currentUserPlanBeneficiaryId } = useUserPlansData();

  const {
    data: patientPrescriptions = [],
    isLoading: isPatientPrescriptionLoading,
  } = useGetPrescriptionsByBeneficiaryIdQuery(
    { beneficiaryId: currentUserPlanBeneficiaryId ?? '' },
    { skip: !currentUserPlanBeneficiaryId },
  );

  // filter to match the useparams to render individual prescriptions
  const { id } = useParams();
  const filteredPrescriptions = patientPrescriptions?.filter(
    (prescriptions: IPrescription) => prescriptions.id === id,
  );

  if (patientPrescriptions.length > 0 && filteredPrescriptions?.length === 0) {
    return <AlertFullPage is404Error={true} />;
  }
  const { toPDF, targetRef } = usePDF({ filename: 'prescription.pdf' });
  if (isPatientPrescriptionLoading) {
    return (
      <Stack w="100%" p={30}>
        <SkeletonComponent height={8} radius={'xl'} />
        <SkeletonComponent height={8} radius={'xl'} />
        <SkeletonComponent height={8} radius={'xl'} />
      </Stack>
    );
  }
  return (
    <div ref={targetRef}>
      <Stack className={classes.root}>
        {filteredPrescriptions.map((prescription: IPrescription) => {
          const { id, beneficiaryName, dateAdded, symptoms } = prescription;
          return (
            <React.Fragment key={id}>
              <Grid>
                <Grid.Col md={12} lg={4}>
                  <Text weight={500}>{beneficiaryName}</Text>
                  {/* <Text>{`${beneficiaryGender}, ${handleCalculateAge(
                    beneficiaryDateOfBirth,
                  )}`}</Text> */}
                  <Text>{symptoms}</Text>
                </Grid.Col>

                <Grid.Col md={12} lg={4}>
                  <ProfessionalDataSignature prescription={prescription} />
                </Grid.Col>
                <Grid.Col md={12} lg={4}>
                  <Box className={classes.logoContainer}>
                    <IconBrandLogo
                      fill={
                        theme.colorScheme === 'dark' ? theme.white : theme.black
                      }
                      homeFill={theme.colors.secondary[3]}
                      className={classes.logo}
                    />
                  </Box>
                </Grid.Col>
              </Grid>

              <Stack className={classes.body} spacing={'xl'}>
                <Group position="center" mb={20}>
                  <Text>Date:</Text>
                  <Text weight={600}>
                    {handleFormatDateWithoutTime(dateAdded)}
                  </Text>
                </Group>
                <PatientPrescriptionTable
                  key={prescription.id}
                  prescription={prescription}
                />

                <Group position="apart">
                  <Group spacing={'xl'} mt={20}>
                    <Button
                      color="cyan"
                      radius="md"
                      size="md"
                      type="submit"
                      loading={isPatientPrescriptionLoading}
                      onClick={() => toPDF()}
                    >
                      {t('activity.Download')}
                    </Button>
                    <Button
                      color="cyan"
                      radius="md"
                      size="md"
                      variant="outline"
                      onClick={() => {
                        window.history.back();
                        window.history.back();
                      }}
                    >
                      {t('activity.Close')}
                    </Button>
                  </Group>
                  <Stack>
                    <Title order={6} italic>
                      Signature
                    </Title>
                    <ProfessionalDataSignature prescription={prescription} />
                  </Stack>
                </Group>
              </Stack>
            </React.Fragment>
          );
        })}
      </Stack>
    </div>
  );
};
